import React, { useState, useMemo } from 'react';
import { Calendar, Search } from 'lucide-react';
import CollapsiblePDFDisplay from "../../components/CollapsiblePDFDisplay";

function formatDate(dateString) {
  // Replace any "-00" patterns with "-01" to represent first of month/January
  const normalizedDate = dateString.replace(/-00/g, '-01');
  
  return new Date(normalizedDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

const EventCard = ({ event, index, pdfUrl, pagesPerChunk }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-100 px-6 pb-1 pt-4 hover:border-blue-100 transition-colors" data-conv-id={event.conv_id} data-chunk-id={event.chunk_id}>
      <div className="flex items-start gap-4">
        <div className="flex-shrink-0">
          <div className="w-12 h-12 rounded-full bg-blue-50 flex items-center justify-center">
            <Calendar className="w-6 h-6 text-blue-500" />
          </div>
        </div>
        <div className="flex-grow">
          <div className="flex justify-between items-center mb-1">
            <div className="text-sm font-medium text-blue-600">
              {formatDate(event.date)}
            </div>
            <div className="text-sm text-gray-600">
              {event.provider}
            </div>
          </div>
          <div className="text-gray-900 mb-3">{event.narrative_description}</div>
          <CollapsiblePDFDisplay
            chunkIds={[event.chunk_id]}
            pdfUrl={event.pdfUrl || pdfUrl}
            pagesPerChunk={pagesPerChunk}
            alertTerms={event.excerpt}
          />
        </div>
      </div>
    </div>
  );
};

const EventList = ({ events, pdfUrl, pagesPerChunk }) => (
  <div className="space-y-4">
    {events.map((event, index) => (
      <div key={`${event.date}-${index}`} className="relative">
        <div className="absolute left-6 top-0 bottom-0 w-px bg-blue-100 -z-10" />
        <EventCard 
          event={event} 
          index={index} 
          pdfUrl={pdfUrl} 
          pagesPerChunk={pagesPerChunk}
        />
      </div>
    ))}
  </div>
);

const NarrativeChronologyDisplay = ({ content, pdfUrl, pagesPerChunk }) => {
  const [viewMode, setViewMode] = useState('important');
  const [searchTerm, setSearchTerm] = useState('');

    const events = useMemo(() => {
      if (!content) return [];
      
      const sourceEvents = viewMode === 'all' ? content.all_events : content.important_events;
      if (!sourceEvents) return [];
      
      let filteredEvents = searchTerm ? sourceEvents.filter(event => {
        const description = String(event.narrative_description || '');
        const excerpts = Array.isArray(event.excerpt) ? event.excerpt : [];
        const term = searchTerm.toLowerCase();
        
        return description.toLowerCase().includes(term) || 
               excerpts.some(excerpt => excerpt.toLowerCase().includes(term));
      }) : sourceEvents;

    // Sort events by date
    const sorted_events = filteredEvents.sort((a, b) => {
        // Handle invalid dates (empty strings, 'N/A', etc.)
        if (!a.date || a.date === 'N/A') return 1;  // Push invalid dates to end
        if (!b.date || b.date === 'N/A') return -1;

        // Replace any remaining -00 with -01 for month and day
        const normalizeDate = (dateStr) => {
            return dateStr
                .replace(/-00/g, '-01')        // Replace -00 with -01
                .replace(/^(\d{4}-\d{2})$/, '$1-01')  // Add day if missing
                .split(' ')[0];  // Remove time portion if it exists
        };

        const dateA = new Date(normalizeDate(a.date));
        const dateB = new Date(normalizeDate(b.date));

        // Handle invalid date objects
        if (isNaN(dateA)) return 1;
        if (isNaN(dateB)) return -1;

        return dateA - dateB;
    });
    return sorted_events;
}, [content, viewMode, searchTerm]);

  if (!content || (!content.all_events && !content.important_events)) {
    return (
      <div className="p-8 text-center text-gray-500 bg-gray-50 rounded-lg">
        No event data available
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Medical Event History</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              View Mode
            </label>
            <select
              value={viewMode}
              onChange={(e) => setViewMode(e.target.value)}
              className="w-full rounded-lg border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="important">Most Important Events</option>
              <option value="all">All Events</option>
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Search Events
            </label>
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search in events..."
                className="w-full rounded-lg border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 pl-10"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
            </div>
          </div>
        </div>

        <div className="bg-blue-50 rounded-lg p-4">
          <div className="flex items-center gap-2 text-sm text-blue-700">
            <span className="font-medium">
              Showing {events.length} {viewMode === 'important' ? 'important' : ''} events
              {searchTerm && ` matching "${searchTerm}"`}
            </span>
          </div>
        </div>
      </div>

      <EventList 
        events={events} 
        pdfUrl={pdfUrl} 
        pagesPerChunk={pagesPerChunk}
      />
    </div>
  );
};

export default NarrativeChronologyDisplay;
